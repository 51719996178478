import React, { useState } from 'react';
import { DropZoneWrapper, UploadButton } from './index.style';
import { MdFileUpload } from "react-icons/md";
import * as api from '../../utils/api';

function DropZone() {
  const [csvFile, setCsvFile] = useState(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'text/csv') {
      setCsvFile(file);

      const response = await api.uploadCSV(file);

      if (response.ok) {
        window.location.reload();  
      }
    } else {
      alert('Please upload a valid CSV file.');
    }
  };

  return (
    <DropZoneWrapper>
      <UploadButton>
        <MdFileUpload style={{ marginRight: '6px', fontSize: '16px' }} />
        <label htmlFor="file-upload">
          {csvFile ? csvFile.name : 'Upload Component'}
        </label>
        <input
          id="file-upload"
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </UploadButton>
    </DropZoneWrapper>
  );
}

export default DropZone;
