import React, { useEffect, useRef } from 'react';
import { GuidanceOverlay, GuidanceContent, Title, GuidanceList, GuidanceItem, ButtonWrapper } from './index.style';

const GuidanceListPopup = ({ guidanceList, onClose }) => {
    const guidanceRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            const mainContainer = document.querySelector('[data-container]');
            
            if (mainContainer && guidanceRef.current) {
                const mainContainerRect = mainContainer.getBoundingClientRect();
                const isClickInsideMainContainer = (
                    e.clientX >= mainContainerRect.left &&
                    e.clientX <= mainContainerRect.right &&
                    e.clientY >= mainContainerRect.top &&
                    e.clientY <= mainContainerRect.bottom
                );

                // don't close if click is inside the main container
                if (!isClickInsideMainContainer) {
                    // check if click is inside the guidance popup itself
                    const isClickInsideGuidance = guidanceRef.current.contains(e.target);
                    if (!isClickInsideGuidance) {
                        onClose();
                    }
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const findings = guidanceList ? guidanceList.split('\n').filter(item => item.trim().startsWith('-')) : [];

    return (
        <GuidanceOverlay ref={guidanceRef}>
            <GuidanceContent>
                <ButtonWrapper>
                </ButtonWrapper>
                <Title>Guidance List</Title>
                <GuidanceList>
                    {findings.length > 0 ? (
                        findings.map((finding, index) => (
                            <GuidanceItem key={index}>{finding.trim().substring(2)}</GuidanceItem>
                        ))
                    ) : (
                        <GuidanceItem>No guidance list is available for this step!</GuidanceItem>
                    )}
                </GuidanceList>
            </GuidanceContent>
        </GuidanceOverlay>
    );
};

export default GuidanceListPopup;