import React, { useEffect, useRef } from 'react';
import { CustomInputArea } from './index.style';

const CustomInput = (props) => {
    const textAreaRef = useRef(null);
  
    const adjustHeight = () => {
      const textarea = textAreaRef.current;
      if (textarea) {
        textarea.style.height = 'auto';
        // height is maximum of min height and size to match the content
        textarea.style.height = `${Math.max(30, textarea.scrollHeight)}px`;
      }
    };
  
    useEffect(() => {
      adjustHeight();
    }, [props.value]);
  
    return (
      <CustomInputArea
        ref={textAreaRef}
        {...props}
        rows={1}
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(e);
          }
          adjustHeight();
        }}
      />
    );
  };
  
  export default CustomInput;