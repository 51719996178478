import styled from 'styled-components';
import colors from '../../configs/colors';
import fontSizes from '../../configs/fontSizes';
import constants from '../../configs/constants';

export const DropZoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* No positioning here so that inline styles from the parent div will work */
`;

export const UploadButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  
  background-color: grey;  /* Changed to light grey */
  color: white;  /* Letters are now white */
  font-size: ${fontSizes.regular}px;
  border-radius: ${constants.borderRadius}px;
  outline: none;
  border: none;
  padding: 8px 12px;

  &:hover {
    cursor: pointer;
    filter: brightness(90%);  /* Slight darkening effect on hover */
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  label {
    color: white;  /* Ensure the label text is also white */
    font-size: ${fontSizes.regular}px;
    cursor: pointer;
  }

  input {
    display: none;
  }
`;
