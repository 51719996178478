import Container from "../../components/Container";
import Wrapper from "../../components/Wrapper";
import {ReactComponent as Logo} from "../../assets/EM_logo.svg"
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import { useState } from "react";
import * as api from '../../utils/api';

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        api.login(username, password)
            .then(data => {
                if(data.access_token) {
                    window.location.href = '/';
                } else {
                    alert('Invalid username or password');
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    return (
        <Container>
            <Wrapper style={{
                display: "flex",
                flexDirection: "column",
                gap: 15,
                justifyContent: "center",
                alignItems: "center",
                height: '100%',
            }}>
                <Logo width="auto" height={36} style={{ display: 'block', width: 'auto', marginBottom: 30 }} />
                <CustomInput
                    label="Username"
                    placeholder="Username"
                    type="text"
                    style={{
                        width: 180,
                        height: 30,
                    }}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                 <CustomInput
                    label="Password"
                    placeholder="Password"
                    type="password"
                    style={{
                        width: 180,
                        height: 30,
                    }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <CustomButton
                    text="Sign in"
                    onClick={handleSubmit}
                    style={{
                        width: 200,
                        height: 30,
                    }}
                />
            </Wrapper>
        </Container>
    );
}